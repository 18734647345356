import React from 'react'
import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { isNull } from 'lodash'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { LAYOUT, SIZE } from '../../index'

// Libs
import { useLiveData } from '@/hooks/useLiveData'
import getDisplayPrice from '@/methods/getDisplayPrice'

// Components
import Image from '@/components/common/Image'

// Methods
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

interface IProps {
  data: IItem
  layout: string
  size: string
  best_selling?: boolean
}

interface IItem {
  productId: number
  productName: string
  minPrice: number
  images: string[]
  minOrigPrice: number
  minLivePrice: number
}

const PopularZoneEventItem = (props: IProps) => {
  const { data, layout, size, best_selling } = props
  const { productId, productName, minPrice, images, minOrigPrice, minLivePrice } = data

  const productImage = images?.[0]
  const router = useRouter()
  const { isLive } = useLiveData({ productId: String(productId) })
  const { displayPrice } = getDisplayPrice({
    isLive,
    origPrice: minOrigPrice,
    minPrice,
    livePrice: minLivePrice,
  })

  const goToProductDetail = () => {
    if (!isNull(productId)) {
      handleRoutePushWithQuery({ router, pathname: `${ROUTE_PATH.PRODUCT}/${productId}` })
    }
  }

  return (
    <div
      onClick={() => goToProductDetail()}
      className={cx('popular-zone-event__item', {
        'popular-zone-event__item--large': size === SIZE.LARGE,
        'popular-zone-event__item--medium': size === SIZE.MEDIUM,
        'popular-zone-event__item--small': size === SIZE.SMALL,
        'popular-zone-event__item--horizontal': layout === LAYOUT.HORIZONTAL,
        'popular-zone-event__item--vertical': layout === LAYOUT.VERTICAL,
        'popular-zone-event__item--vertical-large': layout === LAYOUT.VERTICAL && size === SIZE.LARGE,
        'popular-zone-event__item--best-selling': best_selling,
      })}
    >
      <div className={cx('popular-zone-event__item-image')}>
        <Image src={productImage} alt={productName} fill sizes='(max-width: 500px) 160px,(max-width: 1000px) 320px,(max-width: 1440px) 24vw, 10vw' />
        <div className={cx('popular-zone-event__item-image-mask')}></div>
      </div>

      <div className={cx('popular-zone-event__item-content')}>
        <div className={cx('popular-zone-event__item-title')}>{productName}</div>
        <div className={cx('popular-zone-event__item-price')}>${displayPrice}</div>
      </div>
    </div>
  )
}
export default PopularZoneEventItem
