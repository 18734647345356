import classnames from 'classnames/bind'
import useMedia from '@/hooks/useMedia'
import { useTranslation } from 'next-i18next'

// Libs
import { useRouter } from 'next/router'
import { LiveListData, useLiveList } from '@/components/shared/LiveList/hooks/useLiveList'
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import { handleGtmClickRoomListType, handleGtmClickLiveCalendar } from '@/methods/handleGtmEvent'
import { useAppDispatch } from '@/redux/hooks'
import { resetCalendarData } from '@/redux/reducers/calendar'
import useI18n from '@/hooks/useI18n'

// Components
import Icon from '@/assets/icons'
import Banner from './components/Banner'
import LiveList from '@/components/shared/LiveList'
import Empty from '@/components/shared/Empty'
import TabButton from './components/TabButton'
import PopularZone from './components/PopularZone'

// Methods
import { goToCalendarPage } from '@/methods/goToPage'

// Constants
import { liveTabsData } from '@/constants/liveRoom'
import { ROUTE_PATH } from '@/constants/routePath'
import { GTM_LOCATION, GtmClickLiveCalendarClickText } from '@/constants/gtmEvent'
import { BANNER_LOCATION } from '@/constants/banner'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

const Live = ({ liveDataSSR }: { liveDataSSR: LiveListData }) => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { isDesktop } = useMedia()
  const { query } = router
  const { t } = useTranslation()
  const { isOverseaUser } = useI18n()

  const activeTabIndex = Number(query?.tab) || 0

  const liveListData = useLiveList({
    params: { pageSize: 20, liveTypes: liveTabsData[activeTabIndex]?.value },
    liveDataSSR,
  })

  const handleTabClick = (value: number[]) => {
    const activeIndex = liveTabsData.findIndex((item) => item.value === value)

    handleRoutePushWithQuery({ router, pathname: ROUTE_PATH.LIVE, query: { tab: activeIndex } })
  }

  const handleSendTrackEvent = () => {
    handleGtmClickLiveCalendar({
      location: GTM_LOCATION.LIVE,
      clickText: t(GtmClickLiveCalendarClickText.CALENDAR),
    })
  }

  const handleClick = () => {
    handleSendTrackEvent()
    dispatch(resetCalendarData({}))
    goToCalendarPage(router)
  }

  return (
    <div className={cx('live')}>
      <div className={cx('container')}>
        {!isOverseaUser && <PopularZone />}

        <Banner className={cx('banner__wrapper')} location={BANNER_LOCATION.HOME} />

        <div className={cx('live__title')}>
          <div className={cx('live__title-left')}>
            {liveTabsData.map((tab: any, index: number) => (
              <TabButton
                key={index}
                handleCallback={() => {
                  handleTabClick(tab.value)
                  handleGtmClickRoomListType({ tabType: tab.gtmClickRoomListType, location: GTM_LOCATION.LIVE })
                }}
                selectedIndex={activeTabIndex}
                {...tab}
                index={index}
              />
            ))}
          </div>
          <div className={cx('live__title-right')}>
            <div onClick={handleClick} className={cx('live__title-calendar-button')}>
              <div className={cx('live__title-calendar-icon')}>
                <Icon.Calendar />
              </div>
              {isDesktop && <div className={cx('live__title-calendar-text')}>{t('LiveStreamingPreview_Title')}</div>}
            </div>
          </div>
        </div>
        {/* 若沒資料的話rooList會是null */}
        {liveListData?.data?.pages[0].roomList?.length > 0 ? <LiveList liveListData={liveListData} gtmLocation={GTM_LOCATION.INDEX} /> : <Empty />}
      </div>
    </div>
  )
}

export default Live
